function cookieCnil() {
    if ($.cookie('cookieCnil') === undefined) {
        $('#cookieCnil').fadeIn();

        $(window).click(function () {
            $('#cookieCnil').fadeOut(300);
            $.cookie('cookieCnil', "viewed", {expires: 365});
        });

        $(window).scroll(function () {
            if ($(this).scrollTop() > 1) {
                $('#cookieCnil').fadeOut(300);
                $.cookie('cookieCnil', "viewed", {expires: 365});
            }
        });
    }
}

function hideMenu() {
    var w_body = $('body').width();

    if (w_body > 768) {
        $('body').removeClass('is-desktop');
        $('.overlay--bg').fadeOut();
    }
    $('.burger').removeClass('active').removeClass('icon-PICTOS_USA_quit').toggleClass('icon-PICTOS_USA_burger');
    //Gestion des animations
    $('.submenu').slideUp('fast');
    $('.submenu .subcolumn:first-child').animate({opacity: '0'}, 200);
    $('.submenu .subcolumn:nth-of-type(2)').animate({opacity: '0'}, 200);
    $('.submenu .subcolumn:last-child').animate({opacity: '0'}, 200);
}

// Gestion affichage formulaire home
function openHomeForm($class) {

    var defaultClass = $class,
        blockClass = defaultClass + '.log-block',
        formClass = defaultClass + '-form',
        closeCross = defaultClass + ' .close-cross';

    if ($class === '.log') {
        $('#login_username').attr('autofocus', 'autofocus');
    } else {
        $('#registeremail').attr('autofocus', 'autofocus');
    }

    if (!$(blockClass).hasClass('log-block--active')) {
        $(blockClass).toggleClass('log-block--active');
        $(formClass).slideDown();
        $(closeCross).delay(100).animate({opacity: '1'}, 'fast');
    }
}

// Gestion fermeture formulaire home
function closeHomeForm($class) {
    var defaultClass = $class,
        blockClass = defaultClass + '.log-block',
        formClass = defaultClass + '-form',
        closeCross = defaultClass + ' .close-cross';

    $(blockClass).removeClass('log-block--active');
    $(formClass).slideUp('fast');
    $(closeCross).css('opacity', '0');
}

// Gestion concordance formulaire inscription home
function validateHomeRegister($input, $type) {
    var input = document.getElementById('register' + $input),
        confirm_input = document.getElementById($input + '_confirm');

    function validateInput() {
        if (input.value != confirm_input.value) {
            confirm_input.setCustomValidity('Les champs ' + $type + ' sont différents');
        } else {
            confirm_input.setCustomValidity('');
        }
    }

    input.onchange = validateInput();
    confirm_input.onkeyup = validateInput();
}

function showQuizHelp($class) {
    var myClass = $class;

    if ($(myClass).hasClass('is-active')) {
        $(myClass).removeClass('is-active');
        $(myClass).find('~ .content').slideUp(300);
        $(myClass).find(' span').html('+');
    } else {
        $(myClass).toggleClass('is-active');
        $(myClass).find('~ .content').slideDown(500);
        $(myClass).find(' span').html('-');
    }
}

function confirmExit() {
    return "You have attempted to leave this page.  If you have made any changes to the fields without clicking the Save button, your changes will be lost.  Are you sure you want to exit this page?";
}

/*
 * How to use :
 * nextQuestion($param);
 *
 * @param : current question ID
 */
function nextQuestion(id) {
    $('#question-' + id).slideUp();
    id++;
    $('#question-' + id).slideDown();
}

function nextState(id) {
    $('#state-' + id).slideUp();
    id++;
    $('#state-' + id).slideDown();
}

function startTimer(duration, display) {
    var timer = duration, seconds;
    var __timer = setInterval(function () {
        seconds = parseInt(timer, 10);

        seconds = seconds < 10 ? "0" + seconds : seconds;

        display.textContent = seconds;

        if (--timer < 0) {
            $('#time').html('00');
            if (--timer === -2) {
                window.onbeforeunload = function () {
                    return null;
                },
                $('#time_spent').attr('value', time);
                $('#score-form').submit();
                clearInterval(__timer);
            }
        }
    }, 1000);
}

$(function () {
    cookieCnil(); // Cookie CNIL

    // MENU
    var burger = $('.burger'),
        w_body = $('body').width();

    burger.click(function () {
        if (!burger.hasClass('active')) {
            if (w_body > 768) {
                $('body').toggleClass('is-desktop');
                $('.overlay--bg').fadeIn();
            }
            burger.toggleClass('active icon-PICTOS_USA_quit').removeClass('icon-PICTOS_USA_burger');
            //Gestion des animations
            $('.submenu').slideDown("slow");
            $('.submenu .subcolumn:first-child').delay(100).animate({opacity: '1'}, 'slow');
            $('.submenu .subcolumn:nth-of-type(2)').delay(250).animate({opacity: '1'}, 'slow');
            $('.submenu .subcolumn:last-child').delay(500).animate({opacity: '1'}, 'slow');
        } else {
            hideMenu();
        }
    });

    // Gestion event echap
    $(document).keyup(function (e) {
        if (e.keyCode === 27) {
            if (burger.hasClass('active')) {
                hideMenu();
            }
        }
    });

    // Gestion clic outside
    $(document).mouseup(function (e) {
        var container = $(".full-nav");

        if (!container.is(e.target) && container.has(e.target).length === 0 && burger.hasClass('active')) {
            hideMenu();
        }
    });

    // END MENU


    // FORMS HOME

    // Gestion formulaire connexion
    $('.log .btn-primary--lightgrey').click(function () {
        openHomeForm('.log');
    });

    $('.log .close-cross').click(function () {
        closeHomeForm('.log');
    });

    $('.log-me').click(function () {
        openHomeForm('.log');
    });

    var hash = window.location.hash.substr(1);
    if (hash === 'logme') {
        openHomeForm('.log');
    }

    // Gestion formulaire inscription
    $('.register .btn-primary--lightgrey').click(function () {
        openHomeForm('.register');
    });

    $('.register .close-cross').click(function () {
        closeHomeForm('.register');
    });

    $('.register-form .btn-submit').click(function () {
        validateHomeRegister('password', 'Mots de passe');
        validateHomeRegister('email', 'Emails');
    });

    // END FORMS HOME

    $(function () {
        $('#registerHome').on('submit', function (e) {
            $.ajax({
                type: 'post',
                url: $(this).attr('action'),
                data: $(this).serialize(),
                success: function () {
                }
            });
        });
    });


    var email = $_POST['email_confirm'],
        username = $_POST['username'],
        password = $_POST['password_confirm'];

    $('.username').attr('value', username);
    $('.password').attr('value', password);
    $('.email').attr('value', email);

    // Remplacement backslash formulaire d'enregistrement newsletter
    var str = $('.subscribe label').text();
    var res = str.replace(/\\/g, "");
    $('.subscribe label').text(res);

    // Smooth anchor scroll
    var $root = $('html, body');
    $('a[href*=\\#]').click(function () {
        $root.animate({
            scrollTop: $($.attr(this, 'href')).offset().top
        }, 500);
        return false;
    });

    // Show quiz help
    $('.help-first').click(function () {
        showQuizHelp(this);
    });

    $('.help-second').click(function () {
        showQuizHelp(this);
    });

    //Result percent bar
    var result = $('.percent-bar-plain').attr('data-value');
    $('.percent-bar-plain').animate({
        width: result
    }, 500);

    //Gestion placement bloc expert page d'accueil
    var w_body = $('body').width();
    if (w_body > 991) {
        var w_container = $('.container').width(),
            padd_expert = (w_body - w_container) / 2;
        $('.expert-block').css('padding-left', padd_expert);
    }

    /**
     * Quiz
     *
     * 1) Bouton de vérification de la réponse
     * 2) Bouton question suivante accessible si l'utilisateur à vérifier sa réponse
     * 3) Soumission de la réponse sans vérifier la réponse
     */

        //Gestion prevent quiz leaving
    var id_question = $('.next-question').attr('data-id-question'),
        nb_question = $('input[name="nb_questions"]').val();
    if (id_question < nb_question) {
        window.onbeforeunload = confirmExit;
    }

    $('.verify-answer').click(function () {
        var id_question = $(this).attr('data-id-question');

        // ajax
        $.ajax({
            url: $('#question-' + id_question).find('.quizz-question').attr('action'),
            type: "POST",
            data: $('#question-' + id_question).find('.quizz-question').serialize(),
            cache: false,
            dataType: 'json',
            success: function (data) {
                $('.verify-answer').hide();
                $('#question-' + id_question).find('.submit').hide();

                if (id_question == 1) {
                    $('input[name="id_cpt_answer"]').val(data.cpt_answer_id);
                }

                console.log('Result question: ' + data.result);
                $('#question-' + id_question).find('.next-question').show();
                $('#question-' + id_question).find('.result-answer').show();

                if (data.result) {
                    $('#question-' + id_question).find('.good-answer').show();
                } else {
                    $('#question-' + id_question).find('.bad-answer').show();
                }

                if (id_question == $('input[name="nb_questions"]').val()) {
                    $('.next-question').attr('data-redirect', data.redirect);
                }
            },
            error: function (xhr, ajaxOptions, thrownError) {
                console.log(xhr);
                console.log(ajaxOptions);
                console.log(thrownError);
            }
        });
    });

    $('.next-question').click(function () {
        var id_question = $(this).attr('data-id-question');

        if (id_question == $('input[name="nb_questions"]').val()) {
            //Gestion prevent quiz leaving disable on last question
            window.onbeforeunload = function () {
                return null;
            };
            window.location.href = $(this).attr('data-redirect');
        } else {
            $('html, body').animate({scrollTop: $('#question-' + id_question).offset().top}, 'fast');

            $('.next-question').hide();
            $('#question-' + id_question).find('.result-answer').hide();

            nextQuestion(id_question);

            $('.verify-answer').show();
            $('#question-' + id_question).find('.submit').show();
        }
    });

    $('.quizz-question').submit(function (event) {
        event.preventDefault();

        // Get current question ID
        var id_question = $(this).attr('data-id-question');

        // ajax
        $.ajax({
            url: $(this).attr('action'),
            type: "POST",
            data: $(this).serialize(),
            cache: false,
            dataType: 'json',
            success: function (data) {
                // next question if not last
                if (id_question == $('input[name="nb_questions"]').val()) {
                    //Gestion prevent quiz leaving disable on last question
                    window.onbeforeunload = function () {
                        return null;
                    };
                    //redirect to single answer ID
                    window.location.href = data.redirect;
                } else {
                    $('html, body').animate({scrollTop: $('#question-' + id_question).offset().top}, 'fast');
                    nextQuestion(id_question);

                    // set id_cpt_answer to all input hidden
                    if (id_question == 1) {
                        $('input[name="id_cpt_answer"]').val(data.cpt_answer_id);
                    }
                }
            },
            error: function (xhr, ajaxOptions, thrownError) {
                console.log(xhr);
                console.log(ajaxOptions);
                console.log(thrownError);
            }
        });
    });

    /** QUIZ BOSS **/
    var quiz_score = $('.quiz-score').html();
    if (quiz_score < 50) {
        window.onbeforeunload = confirmExit;
    }

    var state_id,
        svg_id,
        timeleft,
        i = 0,
        j = 0;
    $('.svg-state').click(function () {
        state_id = $('.current-state').attr('data-id');
        svg_id = $(this).attr('data-id');

        if (state_id === svg_id) {
            $(this).addClass('is-right').removeClass('svg-state');
            $('.is-false').addClass('svg-state').removeClass('is-false');
            $('#state-' + (i + 1)).removeClass('current-state').addClass('hidden-state');
            i++;
            $('.quiz-score').text(i);
            $('#score').attr('value', i);
            $('#state-' + (i + 1)).addClass('current-state').removeClass('hidden-state');
            state_id = $('.current-state').attr('data-id');

            if (i === 50) {
                window.onbeforeunload = function () {
                    return null;
                };
                timeleft = $('#time').html();
                $('#time_spent').attr('value', time - timeleft);
                $('#score-form').submit();
            }
        } else {
            if (!$(this).hasClass('is-right') && !$(this).hasClass('is-false')) {
                $(this).addClass('is-false');
                $(this).removeClass('svg-state');
                j++;

                if (j === 10) {
                    window.onbeforeunload = function () {
                        return null;
                    };
                    timeleft = $('#time').html();
                    $('#time_spent').attr('value', time - timeleft);
                    $('#score-form').submit();
                }
            }
        }
    });
    /** END QUIZ BOSS **/
});
